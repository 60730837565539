import { ISiteDesign } from './../models/IApiResponses'
import {
    ACTION_ADD_NOTIFICATION,
    ACTION_CHEK_AUTH,
    ACTION_DELETE_NOTIFICATION,
    ACTION_SET_ADMIN_AUTH,
    ACTION_SET_DOWNLOAD_URLS,
    ACTION_SET_GUEST_AUTH,
    ACTION_SET_GUEST_USER,
    ACTION_SET_LOADING,
    ACTION_SET_ORG,
    ACTION_SET_SITE_DESIGNS,
    ACTION_SET_USER,
    ACTION_SET_USER_REQUEST_LOADING
} from '../utils/reduxConsts'
import {
  get_org,
  guest_login,
  login
} from '../services/api'
import { IGuestUser, INotification } from '../models/IState'
import { IDownloadUrls } from '../models/IApiResponses'

export function setAuth(payload: boolean) {
    if (!payload) {
        localStorage.removeItem('adminToken')
    }
    return {
        type: ACTION_SET_ADMIN_AUTH,
        payload
    }
}

export function setGuestAuth(payload: boolean) {
    if (!payload) {
        localStorage.removeItem('guestToken')
        localStorage.removeItem('phone')
        localStorage.removeItem('isExsistingUser')
        window.location.pathname = '/'
    }
    return {
        type: ACTION_SET_GUEST_AUTH,
        payload
    }
}

export function setDownloadURLs(payload: IDownloadUrls) {
    return {
        type: ACTION_SET_DOWNLOAD_URLS,
        payload
    }
}

export function setLoading(payload: boolean) {
    return {
        type: ACTION_SET_LOADING,
        payload
    }
}

export function setUserRequestLoading(payload: boolean) {
    return {
        type: ACTION_SET_USER_REQUEST_LOADING,
        payload
    }
}

export function checkAuth(username: string, password: string) {
    return async (dispatch: any) => {
        dispatch(setLoading(true))
        try {
            const response = await login(username, password)
            if (response.status === 200) {
                localStorage.setItem('adminToken', response.data.access)
                localStorage.setItem('refresh', response.data.refresh)
                dispatch(getOrg())
                dispatch(setAuth(true))
            }
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(setLoading(false))
        }
        dispatch({
            type: ACTION_CHEK_AUTH
        })
    }
}

export function guestAuth(phone: string) {
    return async (dispatch: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource = urlParams.get("utm_source");
        const utmTag = urlParams.get("utm_tag");
        dispatch(setUserRequestLoading(true))
        try {
            const response = await guest_login(phone, utmSource, utmTag).finally(() => {
                dispatch(setUserRequestLoading(false))
            })
            if (response.status === 200 && 'token' in response.data) {
                localStorage.setItem('guestToken', response.data.token)
                localStorage.setItem('phone', phone)
                localStorage.setItem('isExsistingUser', 'true')
                dispatch(setDownloadURLs(response.data.download_urls))
                dispatch(setGuestAuth(true))
            }
        } catch (e) {
            if (
                !localStorage.getItem('guestToken') &&
                (e as any).response?.status === 404 &&
                'token' in (e as any).response?.data
            ) {
                localStorage.setItem(
                    'guestToken',
                    (e as any).response.data.token
                )
                localStorage.setItem('phone', phone)
                window.location.pathname = '/register'
            } else {
                dispatch(setUserRequestLoading(false))
                // dispatch(
                //     addNotification({
                //         id: v4(),
                //         type: 'Error',
                //         msg: `Server Error`
                //     })
                // )
            }
        }
    }
}

export function getOrg() {
    return async (dispatch: any) => {
        dispatch(setLoading(true))
        try {
            const response = await get_org()
            if (response.status === 200) {
                dispatch(setAuth(true))
                dispatch({
                    type: ACTION_SET_USER,
                    payload: response.data[0].legal_entity
                })
                dispatch({
                    type: ACTION_SET_ORG,
                    payload: response.data[0]
                })
            }
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(setLoading(false))
        }
    }
}

export function addNotification(payload: INotification) {
    return {
        type: ACTION_ADD_NOTIFICATION,
        payload
    }
}

export function deleteNotification(id: string) {
    return {
        type: ACTION_DELETE_NOTIFICATION,
        id
    }
}

export function setGuestUser(payload: IGuestUser) {
    return {
        type: ACTION_SET_GUEST_USER,
        payload
    }
}

export function setSiteDesigns(payload: ISiteDesign) {
    return {
        type: ACTION_SET_SITE_DESIGNS,
        payload
    }
}


// export function guestAuth(phone: string) {
//   return async (dispatch: any) => {
//     dispatch(setUserRequestLoading(true));

//     let response: any
//     try {
//       let guestToken = localStorage.getItem('guestToken');

//       if (!guestToken) {
//         const response = await guest_login(phone);
//         if (response.status === 200 && 'token' in response.data) {
//           guestToken = response.data.token;
//           localStorage.setItem('guestToken', guestToken);
//         }
//       }

//       if (guestToken) {
//         localStorage.setItem('phone', phone);
//         localStorage.setItem('isExsistingUser', 'true');
//         dispatch(setDownloadURLs(response.data.download_urls));
//         dispatch(setGuestAuth(true));
//       }
//     } catch (e) {
//       if (
//         !localStorage.getItem('guestToken') &&
//         (e as any).response?.status === 404 &&
//         'token' in (e as any).response?.data
//       ) {
//         localStorage.setItem('guestToken', (e as any).response.data.token);
//         localStorage.setItem('phone', phone);
//         window.location.pathname = '/register';
//       } else {
//         dispatch(setUserRequestLoading(false));
//         dispatch(
//           addNotification({
//             id: v4(),
//             type: 'Error',
//             msg: 'Ошибка сервера',
//           })
//         );
//       }
//     }
//   };
// }
