import React from 'react';
import MainLayout from '../layout/MainLayout';

const Triggers = () => {
    return (
        <MainLayout>
            Triggers
        </MainLayout>
    );
};

export default Triggers;
