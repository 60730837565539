import React, { FormEvent, useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import {
    get_pay_designs,
    patch_pay_designs,
    post_pay_designs
} from '../../services/api'
import { IState, OrgData } from '../../models/IState'
import { connect, useDispatch } from 'react-redux'
import { IPayDesign } from '../../models/IApiResponses'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'
import { Redirect } from 'react-router-dom'
import QRCode from 'react-qr-code'
import WalCheckbox from '../../components/kit/checkbox/WalCheckbox'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'
import{ SketchPicker } from 'react-color'

interface PayDesignProps {
    orgData: OrgData
}

const PayDesign: React.FC<PayDesignProps> = ({ orgData }) => {
    const dispatch = useDispatch()
    const [files, setFiles] = useState({})
    const [redirect, setRedirect] = useState(false)

    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [data, setData] = useState<IPayDesign>({
        id: 1,
        description: '',
        hex_background_color: '',
        main_image: '',
        text_module_title: '',
        text_module_text: '',
        barcode: 'q',
        alt_text: '',
        logo: '',
        strip: '',
        org: orgData.orgId,
        show_balance: true,
        show_category: true,
        messages: [],
        info: [],
        contacts: []
    })

    const [hexTextColor, setHexTextColor] = useState<string>()
    const [newPay, setNewPay] = useState(false)

    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const handleColorChange = (color: any) => {
        setSelectedColor(color.hex); 
        setData(prevData => ({
          ...prevData,
          hex_background_color: color.hex 
        }));
      };
      const handleTogglePicker = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); 
        setIsPickerOpen(!isPickerOpen);
      };

    useEffect(() => {
        getPayDesigns()
        // eslint-disable-next-line
    }, [])

    const fileChange = (e: any, field: string) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = () => {
            setData({ ...data, [`${field}`]: reader.result })
            setFiles({ ...files, [`${field}`]: file })
            const formData = new FormData()
            formData.append('logo', file)
            console.log(formData.getAll('logo'))
        }
    }
    var setContrast = function (hexcolor: any){
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
        if(hexcolor && hexcolor.length === 6){
            // Convert to RGB value
            var r = parseInt(hexcolor.substr(0,2),16);
            var g = parseInt(hexcolor.substr(2,2),16);
            var b = parseInt(hexcolor.substr(4,2),16);
            // Get YIQ ratio
            var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
            // Check contrast
            var result = 'black';
            if(yiq || yiq === 0){
                result = (yiq >= 128) ? 'black' : 'white';
            }
            setHexTextColor(result)
            return result;
        }else{
            return null;
        }
    };
    const getPayDesigns = () => {
        get_pay_designs()
            .then(res => {
                if (res?.data[0]) {
                    setData(res.data[0])
                    setContrast(res.data[0].hex_background_color ? res.data[0].hex_background_color : '#ffffff')
                } else {
                    setNewPay(true)
                }
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: e.response.data.detail
                    })
                )
            })
    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (newPay) {
            post_pay_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_CREATION_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        } else {
            patch_pay_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_UPDATING_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        }
    }

    if (redirect) {
        return <Redirect to="/admin/design" />
    }

    return (
        <MainLayout>
            <div className="flex lg:flex-row-reverse flex-col-reverse gap-4 h-full overflow-auto">
                <div className="flex flex-col mb-5">
                    <div
                        className="wallet flex flex-col rounded-xl border"
                        style={{
                            backgroundColor: `${data.hex_background_color}`,
                            color: `${hexTextColor}`
                        }}
                    >
                        <div className="flex flex-row self-start items-center gap-3">
                            <div
                                className="w-20 h-20 rounded-full m-2"
                                style={{
                                    backgroundImage: `url('${data.logo}')`
                                }}
                            />
                            <div className="flex flex-col">
                                <span>{data.text_module_title}</span>
                                {/* <span>{data.text_module_text}</span> */}
                            </div>
                        </div>
                        <div className="w-1 border w-full" />
                        <div className="text-2xl px-5">{data.description}</div>

                        <div className="w-full flex flex-row justify-between text-2xl pt-2">
                            {data.show_balance ? 
                            <div className="w-full flex flex-col justify-center px-5 text-2xl pt-2">
                                <span className="text-lg">{LOCALES[currentLocale].BALANCE}</span>
                                <span className="text-xl">0 {LOCALES[currentLocale].POINTS_SHORT}</span>
                            </div> : <div style={{
                                height: '64px',
                                content: '',
                                display: 'block',
                                boxSizing: 'content-box',
                            }}></div>}
                            {data.show_category ? 
                            <div className="w-full flex flex-col justify-center px-5 text-2xl pt-2">
                                <span className="text-lg text-right">{LOCALES[currentLocale].CATEGORY}</span>
                                <span className="text-xl text-right">{LOCALES[currentLocale].SILVER}</span>
                            </div> : <div style={{
                                height: '64px',
                                content: '',
                                display: 'block',
                                boxSizing: 'content-box',
                            }}></div>}
                        </div>

                        <div className="self-center text-center flex flex-col">
                            <div className="bg-white p-5 flex flex-col gap-1 items-center shadow-md">
                                <QRCode
                                    value="5142"
                                    bgColor="#fff"
                                    size={100}
                                />
                            </div>
                            <span>5142</span>
                            {/* <span>{data.alt_text}</span> */}
                        </div>
                        <div
                            className="h-32 w-full mt-2 rounded-bl-xl rounded-br-xl"
                            style={{
                                backgroundImage: `url('${data.strip}')`
                            }}
                        />
                    </div>
                </div>
                <form
                    onSubmit={e => handleFormSubmit(e)}
                    className="w-full flex flex-col gap-4 items-center lg:overflow-y-auto"
                >
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="description"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].BRIEF_CARD_DESCRIPTION}
                        </label>
                        <input
                            type="text"
                            id="description"
                            placeholder={LOCALES[currentLocale].BRIEF_CARD_DESCRIPTION}
                            value={data.description}
                            onChange={e =>
                                setData({
                                    ...data,
                                    description: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            required
                        />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="bg" className="w-80 font-semibold">
                            {LOCALES[currentLocale].BACKGROUND_COLOR_CODE}
                        </label>
                        <button 
                        onClick={handleTogglePicker} 
                        className="mx-auto group relative w-80 flex justify-center py-2  border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >{LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                        </button>
                        <hr className=" border-gray-500" />
                        <div className="mx-auto"> 
                            {isPickerOpen && (
                                <SketchPicker color={selectedColor} onChange={handleColorChange} />
                            )}
                        </div>

                    </div>

                    <div className="flex flex-row gap-1 w-full">
                        <WalCheckbox
                            defaultState={data.show_balance}
                            value={data.show_balance}
                            id="show_balance"
                            onChange={(state: any) =>
                                {
                                    setData({
                                        ...data,
                                        show_balance: !data.show_balance
                                    })
                                }
                            }
                        ></WalCheckbox>
                        <label htmlFor="show_balance" className="w-80 font-semibold">
                            {LOCALES[currentLocale].SHOW_BALANCE}
                        </label>
                    </div>
                    <div className="flex flex-row gap-1 w-full">
                        <WalCheckbox
                            defaultState={data.show_category}
                            value={data.show_category}
                            id="show_category"
                            onChange={(state: any) =>
                                {
                                    setData({
                                        ...data,
                                        show_category: !data.show_category
                                    })
                                }
                            }
                        ></WalCheckbox>
                        <label htmlFor="show_category" className="w-80 font-semibold">
                            {LOCALES[currentLocale].SHOW_CATEGORIES}
                        </label>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="main_image"
                            className="w-80 font-semibold"
                        >
                            {LOCALES[currentLocale].IMAGE_IN_INFORMATION}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={
                                    !data?.main_image?.length ||
                                    (files as any)?.main_image
                                }
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.main_image}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.main_image?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="main_image"
                                placeholder={LOCALES[currentLocale].MAIN_IMAGE}
                                onChange={e => fileChange(e, 'main_image')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="title" className="w-80 font-semibold">
                            {LOCALES[currentLocale].TEXT_MODULE_HEADER}
                        </label>
                        <input
                            id="title"
                            type="text"
                            placeholder={LOCALES[currentLocale].TEXT_MODULE_HEADER}
                            value={data.text_module_title}
                            onChange={e =>
                                setData({
                                    ...data,
                                    text_module_title: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div>
                    {/* <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="text" className="w-80 font-semibold">
                            Содержание текстового модуля
                        </label>
                        <input
                            id="text"
                            type="text"
                            placeholder="Содержание текстового модуля"
                            value={data.text_module_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    text_module_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div> */}
                    {/* <div className="flex flex-col gap-1 w-full">
                        <label
                            htmlFor="alt_text"
                            className="w-80 font-semibold"
                        >
                            Альтернативный текст
                        </label>
                        <input
                            id="alt_text"
                            type="text"
                            placeholder="Альтернативный текст"
                            value={data.alt_text}
                            onChange={e =>
                                setData({
                                    ...data,
                                    alt_text: e.target.value
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        />
                    </div> */}
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="logo" className="w-80 font-semibold">
                            {LOCALES[currentLocale].LOGOTYPE_SHORT}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={
                                    !data?.logo?.length || (files as any)?.logo
                                }
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.logo}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.logo?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="logo"
                                placeholder={LOCALES[currentLocale].LOGOTYPE_SHORT}
                                onChange={e => fileChange(e, 'logo')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                                required={!data?.logo?.length}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <label htmlFor="logo" className="w-80 font-semibold">
                            {LOCALES[currentLocale].CENTRAL_IMAGE}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={
                                    !data?.strip?.length ||
                                    (files as any)?.strip
                                }
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.strip as string}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.strip?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="strip"
                                placeholder={LOCALES[currentLocale].LOGOTYPE_SHORT}
                                onChange={e => fileChange(e, 'strip')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                                required={!data?.strip?.length}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="group relative w-80 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                    </button>
                </form>
            </div>
        </MainLayout>
    )
}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData
    }
}

export default connect(mapStateToProps)(PayDesign)
