import React, { useState } from "react";
import GuestLayout from "../layout/GuestLayout";
import { IState } from "../models/IState";
import { connect, useDispatch } from "react-redux";
import { IDownloadUrls, ISiteDesign } from "../models/IApiResponses";

import AppleIMG from "../assets/A pay.svg";
import GoogleIMG from "../assets/G pay.svg";
import TelegramIMG from "../assets/telegram.png";
import { get_apple_wallet, get_google_pay } from "../services/api";
import { setGuestAuth } from "../store/actions";
import { LOCALES } from "../locales/locales";
import { TLanguages } from "../models/ILocales";

interface GuestCardsProps {
	downloadUrls: IDownloadUrls;
	siteDesigns: ISiteDesign | null;
}

const GuestCards: React.FC<GuestCardsProps> = ({
	downloadUrls,
	siteDesigns,
}) => {
	const dispatch = useDispatch();
	const [currentLocale] = useState<TLanguages>(
		localStorage.getItem("currentLocale") as TLanguages,
	);

	const handleAppleWalletCard = (url: string) => {
		get_apple_wallet(url).then((res) => {
			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: "application/vnd.apple.pkpass" }),
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "card.pkpass");
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleGooglePay = (url: string) => {
		get_google_pay(url).then((res) => {
			setTimeout(() => {
				window.location.href = res.data.url;
			}, 500);
		});
	};

	const changeNumber = () => {
		dispatch(setGuestAuth(false));
	};

	return (
		<GuestLayout>
			<div className="flex flex-col justify-center items-center gap-5 text-white text-center">
				<div className="flex flex-col gap-1">
					<span
						style={{
							textShadow: siteDesigns?.text_shadow
								? "1px 1px 2px black, 0em 0em 6em #000000"
								: "none",
						}}
					>
						{LOCALES[currentLocale].SELECT_APP}
					</span>
					<div className="flex flex-row gap-5">
						<div>
							<img
								src={AppleIMG}
								alt={LOCALES[currentLocale].ADD_THIS_CARD_TO_APPLEWALLET}
								className="w-40 border-white border rounded-2xl cursor-pointer"
								onClick={() => handleAppleWalletCard(downloadUrls.apple)}
							/>
							<span
								style={{
									textShadow: siteDesigns?.text_shadow
										? "1px 1px 2px black, 0em 0em 6em #000000"
										: "none",
								}}
							>
								Apple
							</span>
						</div>
						<div className="overflow-hidden">
							<img
								onClick={() => handleGooglePay(downloadUrls.google)}
								src={GoogleIMG}
								alt={LOCALES[currentLocale].ADD_THIS_CARD_TO_GOOGLEPAY}
								className="w-40 border-white border rounded-2xl cursor-pointer"
							/>
							<span
								style={{
									textShadow: siteDesigns?.text_shadow
										? "1px 1px 2px black, 0em 0em 6em #000000"
										: "none",
								}}
							>
								Android
							</span>
						</div>
					</div>
				</div>
				{siteDesigns && siteDesigns.show_qr ? (
					<span
						className="w-80 break-words"
						style={{
							textShadow: siteDesigns?.text_shadow
								? "1px 1px 2px black, 0em 0em 6em #000000"
								: "none",
						}}
					>
						{LOCALES[currentLocale].SAVE_QR}
					</span>
				) : null}
				{siteDesigns && siteDesigns.show_qr ? (
					<img
						src={downloadUrls.qr_url}
						alt={LOCALES[currentLocale].SCANE_QR}
						className="w-80"
					/>
				) : null}
				{siteDesigns &&
				siteDesigns.use_telegram_bot &&
				siteDesigns.telegram_bot_url ? (
					<div className="flex flex-col justify-center items-center">
						<span
							className="w-80 break-words"
							style={{
								textShadow: siteDesigns?.text_shadow
									? "1px 1px 2px black, 0em 0em 6em #000000"
									: "none",
							}}
						>
							{siteDesigns.telegram_bot_text}
						</span>
						<a
							href={siteDesigns.telegram_bot_url}
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={TelegramIMG}
								alt="Telegram"
								className="w-34 h-12 rounded-2xl mt-2"
							/>
						</a>
					</div>
				) : null}
				<button
					className="btn-request-init group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-30"
					onClick={() => changeNumber()}
					style={{
						backgroundColor:
							(siteDesigns && siteDesigns.change_phone_button_color) ||
							"#4f46e5",
					}}
				>
					{LOCALES[currentLocale].CHANGE_PHONE_NUMBER}
				</button>
			</div>
		</GuestLayout>
	);
};

function mapStateToProps(state: IState) {
	return {
		downloadUrls: state.downloadUrls,
		siteDesigns: state.siteDesigns,
	};
}

export default connect(mapStateToProps)(GuestCards);
