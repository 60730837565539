import React, { FormEvent, useEffect, useState } from 'react'
import LoginLayout from '../layout/LoginLayout'
import { checkAuth, getOrg } from '../store/actions'
import { connect, useDispatch } from 'react-redux'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'
import LocaleSelectorHardcoded from '../components/kit/locale-selector-hardcoded/locale-selector-hardcoded'

interface LoginProps {
  checkAuth: (username: string, password: string) => void
  getOrg: () => void
}

interface Credits {
  username: string
  password: string
}

const Login: React.FC<LoginProps> = () => {
  const dispatch = useDispatch()
  const [currentLocale, setCurrentLocale] = useState<TLanguages>(
    'ru_RU' || 'en_EN'
  )
  useEffect(() => {
    const token = localStorage?.getItem('adminToken')
    if (token) {
      dispatch(getOrg())
    }
    const storageLocale = localStorage.getItem('currentLocale')
    switch (storageLocale) {
      case 'ua_UA':
        setCurrentLocale('ua_UA')
        break
      case 'ru_RU':
        setCurrentLocale('ru_RU')
        break
      case 'en_EN':
        setCurrentLocale('en_EN')
        break
      default:
        setCurrentLocale('ua_UA')
        break
    }
    //eslint-disable-next-line
  }, [])

  const [credits, setCredits] = useState<Credits>({
    username: '',
    password: ''
  })

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(checkAuth(credits.username, credits.password))
  }
  const handleParentFun = (value: any) => {
    localStorage.setItem('currentLocale', value)
    switch (value) {
      case 'ua_UA':
        setCurrentLocale('ua_UA')
        break
      case 'ru_RU':
        setCurrentLocale('ru_RU')
        break
      case 'en_EN':
        setCurrentLocale('en_EN')
        break
      default:
        setCurrentLocale('ua_UA')
        break
    }
  }
  return (
    <LoginLayout>
      <form
        onSubmit={e => handleFormSubmit(e)}
        className="flex flex-col gap-3"
        style={{ width: '100%', height: '100%' }}
      >
        <div
          className="flex flex-col gap-3"
          style={{ padding: '20px' }}
        >
          <LocaleSelectorHardcoded
            handleParentFun={(value: any) => {
              handleParentFun(value)
            }}
          ></LocaleSelectorHardcoded>
        </div>
        <div
          className="flex flex-col gap-3"
          style={{ width: '300px', margin: 'auto' }}
        >
          <input
            type="text"
            placeholder={LOCALES[currentLocale].USER_NAME}
            className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400"
            onChange={e =>
              setCredits({ ...credits, username: e.target.value })
            }
            value={credits.username}
          />
          <input
            type="password"
            placeholder={LOCALES[currentLocale].PASSWORD}
            className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400"
            onChange={e =>
              setCredits({ ...credits, password: e.target.value })
            }
            value={credits.password}
          />
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {LOCALES[currentLocale].LOGIN}
          </button>
        </div>
      </form>
    </LoginLayout>
  )
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkAuth: (username: string, password: string) =>
      dispatch(checkAuth(username, password)),
    getOrg: () => dispatch(getOrg())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
