import React, { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'
import { get_check_code, post_call_code } from '../services/api'
import Loader from './Loader'

interface IConfirmPhoneModalProps {
    closeModal: () => void
    submit: () => void
    phone: string
}

const ConfirmPhoneModal: React.FC<IConfirmPhoneModalProps> = ({
    closeModal,
    submit,
    phone
}) => {
    const timerSeconds = 60
    const [code, setCode] = useState('')
    const [time, setTime] = useState(timerSeconds)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [timerID, setTimerID] = useState<any>()
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [step, setStep] = useState<
        '' | 'code_sent' | 'error_code_sent' | 'code_error'
    >('')

    useEffect(() => {
        sendCode()
        return () => clearInterval(timerID)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (code.length === 4) checkCode()
        //eslint-disable-next-line
    }, [code])

    const timerStart = (count: number) => {
        clearInterval(timerID)
        setTime(count)
        const intervalID = setInterval(() => {
            count = count - 1
            if (count < 1) {
                clearInterval(intervalID)
            }
            setTime(count)
        }, 1000)
        setTimerID(intervalID)
    }

    const sendCode = (retry: boolean = false) => {
        setError('')
        setLoading(true)
        let query = `?phone=${phone}`
        if (retry) query += '&retry=true'

        post_call_code(query)
            .then(() => {
                setStep('code_sent')
                timerStart(timerSeconds)
            })
            .catch(err => {
                setStep('error_code_sent')
                timerStart(timerSeconds)
                switch (err.response.status) {
                    case 400:
                        setError(LOCALES[currentLocale].CODE_ALREADY_SENT)
                        break
                    case 500:
                        setError(LOCALES[currentLocale].SERVICE_ERROR)
                        break
                    default:
                        setError(LOCALES[currentLocale].FAILED_TO_SEND_CODE)
                        break
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const checkCode = () => {
        setError('')
        setLoading(true)
        let query = `?phone=${phone}&code=${code}`

        get_check_code(query)
            .then(res => {
                if (res.data.status === 'BAD') {
                    setStep('code_error')
                    setError(LOCALES[currentLocale].WRONG_CODE)
                }
                if (res.data.status === 'OK') {
                    submit()
                    closeModal()
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getTime = (time: number): string => {
        if (time < 10) return `0:0${time}`
        else if (time < 60 && time > 9) return `0:${time}`
        const minute = Math.floor(time / 60)
        const second = time % 60
        if (second < 10) return `${minute}:0${second}`
        else return `${minute}:${second}`
    }

    return (
        <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-96 h-80 rounded-xl bg-white z-20 shadow-d border-2 border-gray-500 flex flex-col relative">
                {loading ? (
                    <>
                        <div className="absolute w-full h-full bg-gray-500 opacity-50" />
                        <div className="absolute w-full h-full flex items-center justify-center">
                            <Loader color="rgb(79, 70, 229)" />
                        </div>
                    </>
                ) : null}
                <form
                    onSubmit={() => submit()}
                    className="flex flex-col justify-center items-center w-full h-full text-black gap-4"
                >
                    <span>{LOCALES[currentLocale].CODE_SENT_TO_NUMBER} {phone}</span>
                    <span className="font-bold">{LOCALES[currentLocale].ENTER_VALIDATION_DIGITS}</span>
                    <ReactCodeInput
                        type="number"
                        fields={4}
                        name="code"
                        inputMode="tel"
                        value={code}
                        onChange={e => setCode(e)}
                    />
                    {!error.length && step === 'code_sent' ? (
                        <>
                            <span className="text-white text-center">
                            {LOCALES[currentLocale].CODE_SUCCESSFULLY_SENT_TO_NUMBER} {phone}
                            </span>
                            {time < 1 ? (
                                <button
                                    className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition"
                                    onClick={e => {
                                        e.preventDefault()
                                        sendCode(true)
                                    }}
                                >
                                    {LOCALES[currentLocale].SEND_CODE_AGAIN}
                                </button>
                            ) : (
                                <span className="text-white text-center">
                                    {LOCALES[currentLocale].YOU_CAN_RESEND_THE_CODE_VIA}{' '}
                                    {getTime(time)}
                                </span>
                            )}
                        </>
                    ) : null}
                    {error.length && step === 'error_code_sent' ? (
                        <>
                            <span className="text-red-600 text-center font-bold">
                                {error}
                            </span>
                            {time < 1 ? (
                                <button
                                    className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition cursor-pointer"
                                    onClick={e => {
                                        e.preventDefault()
                                        sendCode(true)
                                    }}
                                >
                                    {LOCALES[currentLocale].SEND_CODE_AGAIN}
                                </button>
                            ) : (
                                <span className="text-black text-center">
                                    {LOCALES[currentLocale].YOU_CAN_RESEND_THE_CODE_VIA}{' '}
                                    {getTime(time)}
                                </span>
                            )}
                        </>
                    ) : null}
                    {error.length && step === 'code_error' ? (
                        <>
                            <span className="text-red-600 text-center font-bold">
                                {error}
                            </span>
                            {time < 1 ? (
                                <button
                                    className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition cursor-pointer"
                                    onClick={e => {
                                        e.preventDefault()
                                        sendCode(true)
                                    }}
                                >
                                    {LOCALES[currentLocale].SEND_CODE_AGAIN}
                                </button>
                            ) : (
                                <span className="text-black text-center">
                                    {LOCALES[currentLocale].YOU_CAN_RESEND_THE_CODE_VIA}{' '}
                                    {getTime(time)}
                                </span>
                            )}
                        </>
                    ) : null}
                </form>
            </div>
            <div
                className="absolute inset-0 opacity-25 bg-black z-10"
                onClick={() => closeModal()}
            />
        </div>
    )
}

export default ConfirmPhoneModal
