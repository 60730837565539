import React, { useEffect, useRef, useState } from 'react'

function LocaleSelectorHardcoded(props: any) {
  const [open, setOpen] = useState(false)
  const [flag, setFlag] = useState<string>('ua')
  const toggle = () => setOpen(!open)

  useEffect(() => {
    const storagedLocale = localStorage.getItem('currentLocale')
    switch (storagedLocale) {
      case 'ua_UA':
        setFlag('ua')
        break
      case 'ru_RU':
        setFlag('ru')
        break
      case 'en_EN':
        setFlag('en')
        break
      default:
        setFlag('ua')
        break
    }
  }, [])
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  function handleOnClick(item: any) {
    setFlag(item)
    let selectedLocale = ''
    switch (item) {
      case 'ua':
        selectedLocale = 'ua_UA'
        break
      case 'ru':
        selectedLocale = 'ru_RU'
        break
      case 'en':
        selectedLocale = 'en_EN'
        break
      default:
        selectedLocale = 'ua_UA'
        break
    }
    props.handleParentFun(selectedLocale)
    toggle()
  }

  const flagNames: Record<string, string> = {
    ua: 'UA',
    ru: 'RU',
    en: 'EN'
  }

  return (
    <div
      className="dd-wrapper"
      style={{
        marginLeft: '75%',
        width: 'fit-content',
        padding: '15px 0',
        zIndex: '3'
      }}
      ref={wrapperRef}
    >
      <div
        className="dd-container"
        style={{
          backgroundColor: '#A9A9A9',
          position: 'absolute',
          border: '1px solid black',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
        }}
      >
        <div
          tabIndex={0}
          className="border-blue-400"
          role="button"
          onKeyPress={() => toggle()}
          onClick={() => toggle()}
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '7px'
          }}
        >
          <p style={{ display: 'flex' }}>
            {flagNames[flag]} |
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M7 10l5 5 5-5H7z" />
            </svg>
          </p>
        </div>
        {open && (
          <div
            className="dd-list"
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
          >
            <ul>
              <li
                className="dd-list-item"
                style={{ paddingLeft: '7px' }}
                key={'ua'}
              >
                <button
                  type="button"
                  onClick={() => handleOnClick('ua')}
                >
                  {flagNames['ua']}
                </button>
              </li>
              <li
                className="dd-list-item"
                style={{ paddingLeft: '7px' }}
                key={'ru'}
              >
                <button
                  type="button"
                  onClick={() => handleOnClick('ru')}
                >
                  {flagNames['ru']}
                </button>
              </li>
              <li
                className="dd-list-item"
                style={{ paddingLeft: '7px' }}
                key={'en'}
              >
                <button
                  type="button"
                  onClick={() => handleOnClick('en')}
                >
                  {flagNames['en']}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default LocaleSelectorHardcoded
